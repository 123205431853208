import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */

/* @jsx mdx */

import DefaultLayout from "/Users/Josh.Barnes/josh/c2/denovo/src/templates/homepage.js";
export const _frontmatter = {};
const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">


    <h1>{`this is a page too :/`}</h1>
    <p>{`I am a page!`}</p>
    <h2>{`another heading`}</h2>
    <p>{`sdkjbdkjb`}</p>
    <h3>{`another level`}</h3>
    <p>{`sdlkvjhbasjv`}</p>
    <h2>{`a third`}</h2>
    <p>{`aslkhvakhv`}</p>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      